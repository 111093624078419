
import Vue from "vue";

import {
  activitiesMapActions,
  activitiesMapGetters,
  activitiesMapState
} from "@/store/modules/activities";
import { ITableWrapperColumns } from "@/types";
import { formatDistanceToNow } from "date-fns";
import { userMapGetters } from "@/store/modules/user";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";

interface IListActivitiesData {
  loading: boolean;
  actions: any[];
  actionLinks: any[];
  showAdvancedFilter: boolean;
  collectionName: string;
  tableColumns: ITableWrapperColumns;
}

export default Vue.extend({
  name: "activitiesView",
  components: {
    NoResultsFound
  },
  props: {
    userId: String,
    queryOverride: {
      type: Object
    }
  },
  data(): IListActivitiesData {
    return {
      loading: false,
      actions: [],
      actionLinks: [],
      showAdvancedFilter: true,
      collectionName: "activities",
      tableColumns: {}
    };
  },
  created: function() {
    this.tableColumns = {
      name: {
        formatDataKey: "Name",
        formatDataValue: (arg: string) => arg,
        allowInAdvancedFilter: true,
        allowInTableOptions: true,
        visibleByDefault: true,
        allowSort: true,
        position: 0
      },
      createdOn: {
        formatDataKey: "When",
        formatDataValue: (arg: string) =>
          formatDistanceToNow(new Date(arg), {
            addSuffix: true,
            includeSeconds: true
          }),
        allowInAdvancedFilter: true,
        allowInTableOptions: true,
        visibleByDefault: true,
        allowSort: true,
        position: 1
      }
    };
  },
  methods: {
    ...activitiesMapActions(["getActivities"]),
    async fetchPageData(queryObject: any): Promise<void> {
      try {
        this.loading = true;
        this.$emit("scrollTop");
        await this.getActivities({
          query: {
            ...queryObject,
            __sort: "-createdOn",
            ...this.queryOverride
          },
          drop_store: true
        });
      } catch (error) {
        //@TODO: handle error here? Or maybe not.
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...activitiesMapGetters([
      "getActivitiesFromStore",
      "getActivityLinks",
      "getActivityTotalRecords"
    ]),
    ...activitiesMapState(["makingApiRequest"]),
    ...userMapGetters(["getEditingUser"]),
    activities: function(): any[] {
      return this.getActivitiesFromStore;
    }
  }
});
